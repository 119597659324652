import React, { Fragment, useState } from "react"
import { Helmet } from "react-helmet"
import styled, { ThemeProvider } from "styled-components"
import Theme from "../theme/theme"
import GlobalStyle from "../theme/global-style"
import MenuScreen from "../components/MenuScreen"
import Navigation from "../components/Navigation"
import Section from "../components/Section"
import SplitSection from "../components/SplitSection"
import Footer from "../components/Footer"
import { cursus1, cursus2 } from "../assets/image-urls"
import { P } from "../theme/elements"

const pageContent = {
  title: "Atelier Stöpler — Op Maat Gemaakte Sieraden",
}

const InnerSection = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 4rem 2rem;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  font: 500 100%/1.5 system-ui;

  @media screen and (max-width: 650px) {
    display: block;
    font-size: 80%;
    p {
      text-justify: distribute;
    }
  }
`

const Img1 = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid #ffc15a;
  box-shadow: 0 0 200px -80px rgb(255, 102, 55);
`

const Img2 = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid #ffb3a6;
  box-shadow: 0 0 200px -80px rgb(255, 228, 141);
`

const Text = styled.div`
  max-width: 100%;
  height: auto;

  @media screen and (max-width: 650px) {
    position: relative;
    margin: 0;
    padding: 20px 4px;
  }
`

const Cursus = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openMenu = () => setIsOpen(true)
  const closeMenu = () => setIsOpen(false)
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageContent.title}</title>
        <link rel="canonical" href="https://atelierstopler.nl" />
      </Helmet>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <MenuScreen isOpen={isOpen} closeMenu={closeMenu} />
        {isOpen ? null : (
          <Fragment>
            <Navigation openMenu={openMenu} isOpen={isOpen} />
            <Section title="Cursus">
              <InnerSection>
                <Img1 src={cursus1} alt="Cursus 1" />
                <Text>
                  <P>
                    Bent u altijd al benieuwd geweest hoe u sieraden maakt? Wilt
                    u uw creatieve kant ontdekken en bent u graag bezig met uw
                    handen? Kom kennis maken met het vak onder begeleiding van
                    een ervaren goudsmid.
                  </P>
                  <P>
                    In haar atelier in Woerden geeft Kim cursussen edelsmeden.
                    Tijdens de basis- en vervolgcursus maakt u kennis met de
                    technieken van het vak en werkt u aan uw eigen ontwerpen. De
                    cursus biedt per dagdeel ruimte aan 4 personen. Zo werk u in
                    kleine groepjes waardoor iedereen beter begeleid kan worden.
                  </P>
                </Text>
                <Text>
                  <P>
                    De groepen zijn gemixt van cursisten van verschillende
                    niveaus. Zo kunnen jullie ook van elkaar leren. De cursussen
                    bestaan uit 10 lessen van 2,5 uur. In overleg kan er ook een
                    cursus op maat samengesteld worden. Bij de basis cursus
                    leert u solderen, zagen, vijlen en het afwerken. Bij de
                    vervolgcursus is er meer mogelijkheid om te ontwerpen en dit
                    vervolgens te vervaardigen.
                  </P>
                  <P>
                    LLijkt het u ook leuk om zelf sieraden te maken? Voor meer
                    informatie stuur een mailtje en Kim neemt contact met u op.
                  </P>
                  <P>
                    Of bent u al een goudsmid en zoek u een plek waar u een
                    werkbank kan huren? Laat het weten (er is genoeg plek!)
                  </P>
                </Text>
                <Img2 src={cursus2} alt="Cursus 2" />
              </InnerSection>
            </Section>
            <SplitSection />
            <Footer />
          </Fragment>
        )}
      </ThemeProvider>
    </main>
  )
}

export default Cursus
